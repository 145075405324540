import React from 'react';
import AppRoutes from './routes/AppRoutes';
import { BrowserRouter as Router } from 'react-router-dom';

// main style file
import './style/index.scss'

// for toastify
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <>
      <Router>
        <AppRoutes /> {/* All Routes componennt */}
      </Router>
    </>
  );
}

export default App;
