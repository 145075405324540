import { combineReducers } from 'redux';
import { Reducer } from 'redux';
import loaderReducer from './reducers/loaderReducer';
import { AppState } from './types';
import facebookReducer from './reducers/facebookReducer';


const appReducers: Reducer<AppState> = combineReducers({
   loader: loaderReducer,
   facebook: facebookReducer
});

// for reseting root reducer on logout
const rootReducer = (state: any, action: any) => {
   if (action.type === 'RESET_ALL_DATA') {
      return appReducers(undefined, action)
   }
   return appReducers(state, action)
}

export default rootReducer;
