import { IlistMetaParams } from "../../types/commonInterface";
import { IVerifyCompanyDomain, IVerifyPublicUrl } from "../../types/facebookInterface";
import { FacebookActionTypes, FacebookActions } from "../actionTypes/facebookActionTypes";

export const getTopPerformerFeedLogs = (payload: any): FacebookActionTypes => ({
    type: FacebookActions.GET_TOP_PERFORMER_FEED_LOGS,
    payload
})

export const setTopPerformerFeedLogs = (payload: any): FacebookActionTypes => ({
    type: FacebookActions.SET_TOP_PERFORMER_FEED_LOGS,
    payload
})

export const setTopPerformerFeedLogsParams = (payload: IlistMetaParams): FacebookActionTypes => ({
    type: FacebookActions.SET_TOP_PERFORMER_FEED_LOGS_PARAMS,
    payload
})

export const resetTopPerformerFeedLogs = () => ({
    type: FacebookActions.RESET_TOP_PERFORMER_FEED_LOGS,
})

export const verifyCompanyDomain = (payload: IVerifyCompanyDomain) => ({
    type: FacebookActions.VERIFY_COMPANY_DOMAIN,
    payload
})

export const companyDomainStatus = (payload: boolean) => ({
    type: FacebookActions.COMPANY_DOMAIN_STATUS,
    payload
})

export const verifyPublicUrl = (payload: IVerifyPublicUrl|any) => ({
    type: FacebookActions.VERIFY_PUBLIC_URL,
    payload
})

export const verifyPublicUrlStatus = (payload: boolean) => ({
    type: FacebookActions.VERIFY_PUBLIC_URL_STATUS,
    payload
})

export const setCompanyName = (payload: string) => ({
    type: FacebookActions.SET_COMPANY_NAME,
    payload
})