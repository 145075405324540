import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom'
import { verifyPublicUrl } from '../../redux/actions/facebookAction';
import { AppState } from '../../redux/types';
import TopPerformerFeeds from '../top-performer-feeds/TopPerformerFeeds';
import Error403 from '../error403/Error403';


export default function PublicUrl() {
    const { secretKey } = useParams()
    const dispatch = useDispatch();

    const publicUrlStatus = useSelector((state: AppState) => state.facebook?.publicUrlStatus);

    useEffect(() => {
        dispatch(verifyPublicUrl({ token: secretKey }))
    }, [secretKey, dispatch])


    return (
        <>
            {publicUrlStatus ?
                <TopPerformerFeeds />
                :
                <Error403 />
            }
        </>
    )
}