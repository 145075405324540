import React from "react";

//styles
import styles from './ShimmerEffect.module.css'

type Props ={
  height: string,
  width:string ,
  radius:string ,
  className:string,

}

export default function ShimmerEffect(props:Props) {
  return (
    <div
      className={`${styles.CustomShimmer} ${props.className}`}
      style={{
        height: props.height,
        width: props.width,
        borderRadius: props.radius,
      }}
    ></div>
  );
}
