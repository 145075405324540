export interface FacebookActionTypes {
    type: FacebookActions;
    payload?: any;
}

export enum FacebookActions {
    GET_TOP_PERFORMER_FEED_LOGS = "GET_TOP_PERFORMER_FEED_LOGS",
    SET_TOP_PERFORMER_FEED_LOGS = "SET_TOP_PERFORMER_FEED_LOGS",
    SET_TOP_PERFORMER_FEED_LOGS_PARAMS = "SET_TOP_PERFORMER_FEED_LOGS_PARAMS",
    RESET_TOP_PERFORMER_FEED_LOGS = "RESET_TOP_PERFORMER_FEED_LOGS",
    VERIFY_COMPANY_DOMAIN = "VERIFY_COMPANY_DOMAIN",
    COMPANY_DOMAIN_STATUS = "COMPANY_DOMAIN_STATUS",
    VERIFY_PUBLIC_URL = "VERIFY_PUBLIC_URL",
    VERIFY_PUBLIC_URL_STATUS = "VERIFY_PUBLIC_URL_STATUS",

    SET_COMPANY_NAME = "SET_COMPANY_NAME"
}
