import { delay, put } from "redux-saga/effects";
import { LoaderActions } from "../redux/actionTypes/loaderActionTypes";

export function* startLoader(type : string) {
    switch (type) {
        case "page":
            yield put({ type: LoaderActions.SET_PAGE_LOADER, payload: true });
            break;
        case "list":
            yield put({ type: LoaderActions.SET_LIST_LOADER, payload: true });
            break;
        case "transparent":
            yield put({ type: LoaderActions.SET_TRANSPARENT_LOADER, payload: true });
            break;
        case "img_loader":
            yield put({ type: LoaderActions.SET_IMAGE_LOADER, payload: true });
            break;
        default:
            break;
    }
}

export function* stopLoader(type : string) {
    switch (type) {
        case "page":
            yield delay(1000);
            yield put({ type: LoaderActions.SET_PAGE_LOADER, payload: false });
            break;
        case "list":
            yield put({ type: LoaderActions.SET_LIST_LOADER, payload: false });
            break;
        case "transparent":
            yield put({ type: LoaderActions.SET_TRANSPARENT_LOADER, payload: false });
            break;
        case "img_loader":
            yield put({ type: LoaderActions.SET_IMAGE_LOADER, payload: false });
            break;
        default:
            break;
    }
}

export function onKeyDown(keyEvent : any) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
        keyEvent.preventDefault();
    }
}