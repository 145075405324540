import axios from "axios";
import { apiBaseUrl } from "../configuration";

// initialize axios 
const api = axios.create({
    baseURL: apiBaseUrl,
    headers: {
        common: { 'Authorization': `Bearer ${localStorage.getItem("accessToken") ?? ""}` }
    },
    withCredentials: true,
});



// for refresh token
let isRefreshing = false;
let failedQueue: any = [];
// run failed request after update token
const processQueue = (error: any, token = null) => {
    failedQueue.forEach((prom: any) => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });
    failedQueue = [];
};

// interceptor for refresh token 
api.interceptors.response.use(
    (response) => {
        return response;
    },
    async (err) => {
        const originalRequest = err.config;
        if (err.response.status === 401 && !originalRequest._retry) {
            if (isRefreshing) {
                return new Promise(function (resolve, reject) {
                    failedQueue.push({ resolve, reject });
                })
                    .then(token => {
                        originalRequest.headers['Authorization'] = 'Bearer ' + token;
                        return axios(originalRequest);
                    })
                    .catch(err => {
                        return Promise.reject(err);
                    });
            }
            originalRequest._retry = true;
            isRefreshing = true;

            return new Promise(function (resolve, reject) {
                axios.post(`${apiBaseUrl}/api/user/token/refresh`,
                    {},
                    {
                        headers: {
                            "Authorization": `Bearer ${localStorage.getItem('accessToken')}`
                        }
                    })
                    .then(res => {
                        localStorage.setItem('accessToken', res.data?.data?.accessToken);
                        api.defaults.headers.common['Authorization'] = 'Bearer ' + res?.data?.data?.accessToken;
                        originalRequest.headers['Authorization'] = 'Bearer ' + res.data?.data?.accessToken;
                        processQueue(null, res?.data?.data?.accessToken);
                        resolve(axios(originalRequest));
                        isRefreshing = false;
                    })
                    .catch(err => {
                        window.localStorage.clear();
                        // store.reduxStore.dispatch(resetAllData());
                    })
            });
        }
        return Promise.reject(err);
    });

export default api;
