import { toast } from 'react-toastify';
import { FacebookActionTypes, FacebookActions } from '../../actionTypes/facebookActionTypes';
import { call, delay, put, takeLatest } from 'redux-saga/effects';
import { startLoader, stopLoader } from '../../../common-functions/common-functions';
import { getTopPerformerFeedLogsApi, verifyCompanyDomainApi, verifyPublicUrlApi, } from '../requests/facebookRequests';
import { companyDomainStatus, setCompanyName, setTopPerformerFeedLogs, setTopPerformerFeedLogsParams, verifyPublicUrlStatus } from '../../actions/facebookAction';



function* getTopPerformerFeedLogsHandler(action: FacebookActionTypes) {
    yield delay(500);
    yield startLoader("list");
    try {
        const { data } = yield call(getTopPerformerFeedLogsApi, action.payload);
        if (data.status) {
            yield put(setTopPerformerFeedLogs(data?.data));
            yield put(setTopPerformerFeedLogsParams(data?.meta_params?.pagination));
        } else {
            toast.error("Some Error Occurred...!", { position: toast.POSITION.TOP_RIGHT })
        }
    } catch (error: any) {
        toast.error(error.response.data?.message ?? "Some Error Occurred...!", {
            position: toast.POSITION.TOP_RIGHT
        })
    }
    finally {
        yield stopLoader("list");
        yield stopLoader("transparent")
    }
}

function* verifyCompanyDomainHandler(action: FacebookActionTypes) {
    yield startLoader("transparent");
    try {
        const { data } = yield call(verifyCompanyDomainApi, action.payload);

        if (data.status) {
            yield put(companyDomainStatus(data?.status))
        } else {
            yield put(companyDomainStatus(false))
            toast.error("Some Error Occurred...!", { position: toast.POSITION.TOP_RIGHT })
        }
    } catch (error: any) {
        toast.error(error.response.data?.message ?? "Some Error Occurred...!", {
            position: toast.POSITION.TOP_RIGHT
        })
    }
    finally {
        yield stopLoader("transparent")
    }
}


function* verifyPublicUrlHandler(action: FacebookActionTypes) {
    yield startLoader("transparent");
    try {
        const { data } = yield call(verifyPublicUrlApi, action.payload);

        if (data.status) {
            yield put(verifyPublicUrlStatus(data?.status))
            yield put(setCompanyName(data?.data?.companyName))
        } else {
            yield put(verifyPublicUrlStatus(false))
            toast.error(data?.message, { position: toast.POSITION.TOP_RIGHT })
        }
    } catch (error: any) {
        toast.error(error.response.data?.message ?? "Some Error Occurred...!", {
            position: toast.POSITION.TOP_RIGHT
        })
    }
    finally {
        yield stopLoader("transparent")
    }
}

export default function* facebookSaga() {
    yield takeLatest(FacebookActions.GET_TOP_PERFORMER_FEED_LOGS, getTopPerformerFeedLogsHandler);
    yield takeLatest(FacebookActions.VERIFY_COMPANY_DOMAIN, verifyCompanyDomainHandler);
    yield takeLatest(FacebookActions.VERIFY_PUBLIC_URL, verifyPublicUrlHandler)
}