import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getTopPerformerFeedLogs, resetTopPerformerFeedLogs } from '../../redux/actions/facebookAction';
import { AppState } from '../../redux/types';
import TransparentLoader from '../../components/transparent-loader/TransparentLoader';
import ShimmerEffect from '../../components/shimmer-effect/ShimmerEffect';
import noFeedFoundImage from '../../assets/images/no-feed.png'
import { setTransparentLoader } from '../../redux/actions/loaderAction';
import { useParams } from 'react-router-dom';


export default function TopPerformerFeeds() {
    const dispatch = useDispatch();
    const transparentLoader = useSelector((state: AppState) => state?.loader?.transparentLoader);
    const listLoader = useSelector((state: AppState) => state?.loader?.listLoader);
    const topPerformerFeedList = useSelector((state: AppState) => state.facebook?.topPerformerFeedLogs);
    const topPerformerFeedListParams = useSelector((state: AppState) => state.facebook?.topPerformerFeedLogsParams);
    const companyName = useSelector((state: AppState)=> state.facebook?.companyName)
    const { companySlug } = useParams()
    const [timeOut, setTimeOut] = useState(true);

    const [compnaySlugName, setCompanySlugName] = useState("")

    useEffect(() => {
      if(companySlug){
        setCompanySlugName(companySlug)
      }else{
        setCompanySlugName(companyName)
      }
    }, [companySlug])


    useEffect(() => {
        setTimeout(() => {
            setTimeOut(false)
        }, 5000);
    },[timeOut])

    useEffect(() => {
        dispatch(setTransparentLoader(true));
    }, [dispatch])


    useEffect(() => {
        dispatch(resetTopPerformerFeedLogs())
        dispatch(getTopPerformerFeedLogs({ companyId: compnaySlugName, page: 0 }))
    }, [dispatch, compnaySlugName])

    const loadMoreHandler = () => {
        dispatch(getTopPerformerFeedLogs({ companyId:compnaySlugName, page: topPerformerFeedListParams?.current_page + 1 }))
    }


    return (
        <>
            <TransparentLoader loading={transparentLoader} />
            {/* Navbar start here */}
            <div className='px-[40px] md:px-[80px] text-center border-b-2 border-gray-100 shadow-md py-4'>

                <div className='grid grid-cols-12 md:grid-cols-8'>
                    <div className='md:col-start-3 col-span-12 md:col-span-4'>
                        <div className='flex justify-between'>
                            <div className='flex mb-2'>
                                {topPerformerFeedList?.company?.logo &&
                                    <div className='rounded-full'>
                                        <img src="https://app.tracx.io/logo192.png" className='w-[32px] h-[32px]' alt="company" />
                                    </div>
                                }
                                <p className='ml-2 text-xl text-[#0A0917] font-bold hidden lg:block'>Tracxio</p>
                            </div>
                            <div className='flex mb-2'>
                                {topPerformerFeedList?.company?.logo &&
                                    <img src={topPerformerFeedList?.company?.logo} className='w-[32px] h-[32px]' alt="company" />
                                }
                                <p className='ml-2 text-xl text-[#0A0917] font-bold hidden lg:block'>{topPerformerFeedList?.company?.name}</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            {/* Navbar end here */}

            <div className='bg-[#F5F5FA]'>

                <p className='text-lg text-center py-4 text-[#49454B]'>
                    Top Performer Feeds
                </p>

                <div className="p-2 px-[40px] md:px-[80px]">
                    {transparentLoader ?
                        <div className='grid grid-cols-8'>
                            <div className='col-start-3 col-span-4 p-4 mb-8'>
                                <div className='flex items-center mb-4'>

                                    <ShimmerEffect
                                        className="mr-4"
                                        height="50px"
                                        width="50px"
                                        radius="50px" />

                                    <ShimmerEffect
                                        className=""
                                        height="40px"
                                        width="20%"
                                        radius="10px" />

                                </div>
                                <div>
                                    {["This", "Used", "For", "Dummy", "Purpose"].map((item, index) => (
                                        <ShimmerEffect
                                            className="mb-1"
                                            height="18px"
                                            width="60%"
                                            radius="4px" />
                                    ))}
                                    <ShimmerEffect
                                        className="mt-4"
                                        height="600px"
                                        width="100%"
                                        radius="" />
                                </div>
                            </div>

                        </div>
                        :
                        <>
                            {topPerformerFeedList?.feeds?.length === 0 && !timeOut &&
                                <><div className='grid grid-cols-8'>
                                    <div className='md:col-start-3 col-span-8 md:col-span-4 mb-8'>
                                        <img src={noFeedFoundImage} className="mx-auto" alt="add customer" />
                                    </div>
                                </div>
                                </>
                            }
                            <div className='grid grid-cols-8'>
                                <>
                                    {topPerformerFeedList?.feeds?.map((item: any, index: any) => (
                                        <div className='md:col-start-3 col-span-12 md:col-span-4 mb-8'>
                                            <div>
                                                <div className='border-2 border-gray-300 rounded-lg bg-white'>
                                                    <div className='p-4 rounded-t-lg'>


                                                        <div className='border-b-2 w-full pb-2'>
                                                            <p className='me-2'>{item?.postedDate}</p>
                                                        </div>

                                                        <div className='mt-4'>
                                                            <p className='text-base text-[#1D2129]'>
                                                                {item?.postedContent}
                                                            </p>

                                                            {item?.userList?.map((userItem: any, userIndex: any) => (
                                                                <p className="text-base text-[#1D2129] font-semibold">
                                                                    {userItem}
                                                                </p>
                                                            ))}

                                                        </div>
                                                    </div>
                                                    <div className='flex justify-center' style={{ "backgroundColor": item.backgroundColor + "80" }}>
                                                        <img className='w-[400px]' src={item?.postedImage} onError={(e) => (e.currentTarget.src = "https://images.placeholders.dev/?width=200&height=200&text=Oops!")} alt="top performer feeds" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </>
                            </div>


                            {listLoader &&
                                <div className='grid grid-cols-8'>
                                    <div className='md:col-start-3 col-span-12 md:col-span-4 mb-8'>
                                        <ShimmerEffect
                                            className="mt-4"
                                            height="100px"
                                            width="100%"
                                            radius="8px" />

                                    </div>
                                </div>
                            }

                            {topPerformerFeedList?.feeds?.length > 0 &&

                                <div className='text-center mt-4 mb-10'>
                                    {topPerformerFeedListParams?.has_more_page === true ?
                                        <button type="button" className='btn-primary' onClick={() => loadMoreHandler()} disabled={listLoader}>
                                            {listLoader ? "Please wait..." : "Load More"}
                                        </button>
                                        :
                                        <p className='text-xl font-semibold'>No More Post.</p>
                                    }
                                </div>

                            }
                        </>
                    }
                </div>

            </div>

        </>
    )
}