import { LoaderActions, LoaderTypes } from "../actionTypes/loaderActionTypes";

export const setSearchLoader = (payload: boolean): LoaderTypes  => {
    return {
        type: LoaderActions.SET_SEARCH_LOADER,
        payload
    }
}

export const setPageLoader = (payload: boolean): LoaderTypes => {
    return {
        type: LoaderActions.SET_PAGE_LOADER,
        payload
    }
}

export const setTransparentLoader = (payload: boolean): LoaderTypes => {
    return {
        type: LoaderActions.SET_TRANSPARENT_LOADER,
        payload
    }
}

export const setListLoader = (payload: boolean): LoaderTypes => {
    return {
        type: LoaderActions.SET_LIST_LOADER,
        payload
    }
}


export const setImageLoader = (payload: boolean): LoaderTypes => {
    return {
        type: LoaderActions.SET_IMAGE_LOADER,
        payload
    }
}