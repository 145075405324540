import React from 'react'
import { Route, Routes } from "react-router-dom";
import Iframe from '../pages/iframe/Iframe';
import Error403 from '../pages/error403/Error403';
import Error404Image from '../assets/images/404-error-page.jpg'
import PublicUrl from '../pages/publicUrl/PublicUrl';

export default function AppRoutes() {
    return (
        <>
            <Routes>
                <Route path="/" element={<Error403 />} />
                <Route path="/:secretKey" element={<PublicUrl />} />
                <Route path="/iframe/:companySlug/:secretKey" element={<Iframe />} />
                <Route path="*" element={<div className='w-3/5 mx-auto mt-10'><img src={Error404Image} alt="Page not found" /></div>} />
            </Routes>
        </>
    )
}
