import { IFacebookInitialState } from "../../types/facebookInterface";
import { FacebookActionTypes, FacebookActions } from "../actionTypes/facebookActionTypes";

const initialState: IFacebookInitialState = {

    topPerformerFeedLogs: {
        company: {},
        feeds: []
    },
    topPerformerFeedLogsParams: {},
    companyDomainStatus: false,
    publicUrlStatus: false,
    companyName: ""
}

export default function facebookReducer(state = initialState, action: FacebookActionTypes): IFacebookInitialState {
    switch (action.type) {

        case FacebookActions.SET_TOP_PERFORMER_FEED_LOGS:
            // const feedLogsArray = [...state.topPerformerFeedLogs.feeds, ...action.payload?.feeds]
            return {
                ...state,
                topPerformerFeedLogs: {
                    company: action.payload.company || state.topPerformerFeedLogs.company,
                    feeds: [...state.topPerformerFeedLogs.feeds, ...action.payload.feeds]
                }
            };
        case FacebookActions.SET_TOP_PERFORMER_FEED_LOGS_PARAMS:
            return {
                ...state,
                topPerformerFeedLogsParams: action.payload
            }

        case FacebookActions.RESET_TOP_PERFORMER_FEED_LOGS:
            return {
                ...state,
                topPerformerFeedLogs: {
                    company: {},
                    feeds: [],
                },
            };

        case FacebookActions.COMPANY_DOMAIN_STATUS:
            return {
                ...state,
                companyDomainStatus: action.payload
            }

        case FacebookActions.VERIFY_PUBLIC_URL_STATUS:
            return {
                ...state,
                publicUrlStatus: action.payload
            }

        case FacebookActions.SET_COMPANY_NAME:
            return{
                ...state,
                companyName: action.payload
            }


        default:
            return state;
    }
}
