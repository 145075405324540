import { IVerifyCompanyDomain, IVerifyPublicUrl } from "../../../types/facebookInterface";
import api from "../../../utils/api";

export const getTopPerformerFeedLogsApi = async (data: any) => {
    return await api.get(`/api/social-post-logs/facebook-logs/${data?.companyId}?page=${data?.page ?? 0}`);
}

export const verifyCompanyDomainApi = async (data: IVerifyCompanyDomain) => {
    return await api.post(`api/feeds/iframe/verify-domain`, data);
}

export const verifyPublicUrlApi = async (data: IVerifyPublicUrl) => {
    return await api.post(`api/feeds/verify-token`, data)
}