import React, { useEffect, useState } from 'react'
import TopPerformerFeeds from '../top-performer-feeds/TopPerformerFeeds';
import Error403 from '../error403/Error403';
import { useNavigate } from 'react-router-dom';

export default function Iframe() {
    const navigate = useNavigate();
    // const [isOpenInIframe, setIsOpenInIframe] = useState<boolean>(false);


    useEffect(() => {
        if (window.location !== window.parent.location) {
            // The page is in an iFrames
            console.log("The page is in an iFrame");
            // setIsOpenInIframe(true)
        }
        else {
            // The page is not in an iFrame
            console.log("The page is not in an iFrame");
            // setIsOpenInIframe(false)
            navigate("/")

        }
    }, [navigate])





    return (
        <TopPerformerFeeds />
    )
}
