import { useRef, useEffect } from 'react';
import Lottie, { AnimationItem } from 'lottie-web';
import animationData from '../../assets/lottie/tcxLottie.json';

type Props = {
  loading: boolean;
};

const TransparentLoader = (props: Props) => {
  const container = useRef<HTMLDivElement>(null);
  const animation = useRef<AnimationItem | null>(null);

  useEffect(() => {
    if (container.current) {
      animation.current = Lottie.loadAnimation({
        container: container.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: animationData,
      });
    }

    return () => {
      if (animation.current) {
        animation.current.destroy();
      }
    };
  }, [props.loading]);

  useEffect(() => {
    if (animation.current && props.loading) {
      animation.current.play();
    }
  }, [props.loading]);

  return (
    <>
      {props.loading && (
        <div className='flex items-center justify-center z-[1099]  h-screen fixed bottom-0 left-0 top-0 right-0 bg-[#32323252]'>
          <div
            className='d-inline'
            ref={container}
            style={{
              width: '60%',
              height: '60%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          />
        </div>
      )}
    </>
  );
};

export default TransparentLoader;
