import { ILoaderInitialState } from "../../types/loaderInterface";
import { LoaderActions, LoaderTypes } from "../actionTypes/loaderActionTypes";

const initialState : ILoaderInitialState = {
    pageLoader: false,
    searchLoader: false,
    listLoader: false,
    transparentLoader: false,
    imageLoader: false
}


export default function loaderReducer(state = initialState, action: LoaderTypes) : ILoaderInitialState {
    switch (action.type) {
        case LoaderActions.SET_SEARCH_LOADER:
            return {
                ...state,
                searchLoader: action.payload
            };
        case LoaderActions.SET_PAGE_LOADER:
            return {
                ...state,
                pageLoader: action.payload
            };
        case LoaderActions.SET_LIST_LOADER:
            return {
                ...state,
                listLoader: action.payload
            };
        case LoaderActions.SET_TRANSPARENT_LOADER:
            return {
                ...state,
                transparentLoader: action.payload
            };
        case LoaderActions.SET_IMAGE_LOADER:
            return {
                ...state,
                imageLoader: action.payload
            };
        default:
            return state;
    }
}