export interface LoaderTypes {
    type: LoaderActions;
    payload?: any;
}

export enum LoaderActions {
    SET_SEARCH_LOADER = 'SET_SEARCH_LOADER',
    SET_PAGE_LOADER = 'SET_PAGE_LOADER',
    SET_LIST_LOADER = 'SET_LIST_LOADER',
    SET_TRANSPARENT_LOADER = 'SET_TRANSPARENT_LOADER',
    SET_IMAGE_LOADER = 'SET_IMAGE_LOADER'
}