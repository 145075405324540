import React from 'react'
import error403Image from '../../assets/images/403-error.jpg'

export default function Error403() {
    return (
        <>

            <div className='grid grid-cols-8'>
                <div className='md:col-start-3 col-span-8 md:col-span-4 mb-8'>
                    <div>
                        <img src={error403Image} className="mx-auto" alt="add customer" />
                    </div>
                    {/* <div className='text-center'>
                        <button type="button" className='btn-primary'>
                            Go Back
                        </button>
                    </div> */}
                </div>
            </div>

        </>
    )
}
